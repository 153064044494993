import React from 'react';
import IconImage from '../iconImage';
import Icon from '../icon';

const Component = ({icon, ...props}) => {
  const {name, path} = icon;
  if (name) {
    return <Icon name={name} {...props} />;
  }
  return <IconImage source={path} {...props} />;
};

export default Component;
