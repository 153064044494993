import React, {useContext} from 'react';
import ReactGA from 'react-ga';
import CMusicButton from '../../common/cMusicButton';
import {AVAILABLE_SOCIAL_STREAMINGS, socialStreamingComparator} from '../../../configs/serviceAssets';
import Context from '../context';

const Component = ({streamings = []}) => {
  const streamingLinks = streamings
    .map((item) => {
      const catalog = AVAILABLE_SOCIAL_STREAMINGS.find(
        ({id}) => item.id === id,
      );
      return {
        catalog,
        item,
      };
    })
    .filter(({catalog}) => catalog)
    .filter(({item: {valueSwitch}}) => valueSwitch)
    .sort(socialStreamingComparator)
    .map(({catalog, item}) => ({...catalog, ...item}));
  const themeContext = useContext(Context);
  return (
    <div
      style={{
        flex: 1,
        width: '100%',
        // paddingTop: 20,
      }}>
      <div
        style={{
          flex: 1,
          // width: '100%',
          alignItems: 'center',
        }}>
        <div
          style={{
            backgroundColor: '#333333',
            color: 'white',
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 15,
            paddingRight: 15,
            textAlign: 'center',
            letterSpacing: 4.2,
            fontSize: 15,
          }}>
          AVAILABLE NOW
        </div>
        {streamingLinks.map(({value, banner, id: streamingId}) => (
          <div
            style={{
              height: 80,
            }}
            key={streamingId}>
            <a
              href={value}
              className={streamingId}
              style={{
                textDecoration: 'none',
              }}>
              <CMusicButton
                source={
                  themeContext.isDarkTheme ? banner.src.light : banner.src.dark
                }
                isDarkTheme={themeContext.isDarkTheme}
                onClick={() => {
                  ReactGA.event({
                    action: 'linkVisited',
                    category: streamingId,
                    label: streamingId,
                  });
                  window.fbq(
                    'track',
                    'ViewContent',
                    {
                      content_name: streamingId,
                      content_category: 'music_link_click',
                    }
                  );
                }}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Component;
