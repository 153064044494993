import React, {useEffect, useState, useContext} from 'react';
import CImageBackground from '../common/cImageBackground';
import CoverAndStreamingSection from './sections/coverAndStreamingSection';
import VisitSection from './sections/visitSection';
import SocialSection from './sections/socialSection';
import VideoAndAvatarSection from './sections/videoAndAvatarSection';
import {getVideoThumbnail} from '../../services/api/linksService';
import ClientError from '../../lib/utils/exceptions';
import YoutubeSection from './sections/youtubeSection';
import {
  SOCIALS_SECTION_ID,
  VISIT_SECTION_ID,
  STREAMING_SECTION_ID,
  VIDEO_SECTION_ID,
  AVATAR_SECTION_ID,
  COVER_SECTION_ID,
} from '../../configs/serviceSettingOptions';
import cuckooImgWhite from '../../res/images/imagoW/light.png';
import cuckooImgBlack from '../../res/images/imagoW/dark.png';
import Context from './context';

const ViewLinks = ({
  sectionsActivation,
  sectionsOrder,
  backgroundType,
  artistName,
  description,
  video,
  avatar,
  cover,
  coverTitle,
  visits,
  socials,
  streamings,
  isDarkTheme,
  pageId,
  embed,
}) => {
  const themeContext = useContext(Context);
  const [videoPlay, setVideoPlay] = useState(false);
  const [thumbnailVideo, setThumbnailVideo] = useState(null);
  const [errorThumbnail, setErrorThumbnail] = useState(null);

  useEffect(() => {
    if (video) {
      getVideoThumbnail(video).then(
        (data) => setThumbnailVideo(data),
        (err) => {
          setErrorThumbnail(
            err instanceof ClientError ? err.message : 'Internal Error',
          );
        },
      );
    } else {
      setThumbnailVideo(null);
    }
  }, [video]);

  const activeSections = sectionsActivation
    .filter(({active}) => active)
    .map(({id}) => id);

  const disabledCover =
    !activeSections.includes(COVER_SECTION_ID) || cover === null;

  const activeVideoSection = activeSections.includes(VIDEO_SECTION_ID);
  const hasVideo = video !== '';
  const disableVideo = !activeVideoSection || !hasVideo;

  const sections = [
    [
      <CoverAndStreamingSection
        coverTitle={coverTitle}
        cover={cover}
        streamings={streamings}
        disabledStreaming={!activeSections.includes(STREAMING_SECTION_ID)}
        disabledStreamings={
          !activeSections.includes(STREAMING_SECTION_ID) ||
          streamings.length === 0
        }
        disabledCover={disabledCover}
        disableVideo={disableVideo}
      />,
      STREAMING_SECTION_ID,
    ],
    [
      activeSections.includes(VISIT_SECTION_ID) && visits.length > 0 && (
        <VisitSection
          style={{
            marginTop: 10,
            marginBottom: 10,
            flex: 1,
            display: 'flex',
          }}
          values={visits}
          pageId={pageId}
        />
      ),
      VISIT_SECTION_ID,
    ],
    [
      activeSections.includes(SOCIALS_SECTION_ID) && socials.length > 0 && (
        <SocialSection
          style={{
            marginTop: 10,
            marginBottom: 10,
            flex: 1,
          }}
          socials={socials}
        />
      ),
      SOCIALS_SECTION_ID,
    ],
  ].sort(([node_1, pk_1], [node_2, pk_2]) =>
    sectionsOrder
      ? sectionsOrder.indexOf(pk_1) - sectionsOrder.indexOf(pk_2)
      : 0,
  );
  const content = (
    <div
      style={{
        width: embed && window && window.innerWidth ? window.innerWidth : 400,
        boxShadow: '0px 0px 13px 4px black',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <VideoAndAvatarSection
        setVideoPlay={setVideoPlay}
        avatar={avatar}
        thumbnailVideo={thumbnailVideo}
        cover={cover}
        disabledVideo={disableVideo && disabledCover}
        disableVideo={disableVideo}
        disabledAvatar={
          !activeSections.includes(AVATAR_SECTION_ID) || avatar === null
        }
        video={video}
        embed={embed}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginTop: 20,
          flex: 1,
        }}>
        <span
          style={{
            fontSize: 24,
            letterSpacing: 5,
            fontWeight: 'bold',
            textAlign: 'center',
            paddingBottom: 5,
            color: isDarkTheme
              ? 'white'
              : themeContext.isBlurredBackground
              ? 'white'
              : 'black',
          }}>
          {artistName}
        </span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '60%',
          }}>
          <div
            style={{
              display: 'flex',
            }}>
            <span
              style={{
                fontSize: 12,
                letterSpacing: 3,
                color: isDarkTheme
                  ? 'white'
                  : themeContext.isBlurredBackground
                  ? 'white'
                  : 'black',
                marginTop: 5,
                paddingBottom: 15,
                textAlign: 'center',
              }}>
              {description}
            </span>
          </div>
        </div>
        {sections.map(([node, pk]) => (
          <React.Fragment key={pk}>{node}</React.Fragment>
        ))}
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: 40,
        }}>
        {isDarkTheme ? (
          <img
            src={cuckooImgWhite}
            alt="logo_cuckoo_white"
            width={30}
            height={30}
          />
        ) : themeContext.isBlurredBackground ? (
          <img
            src={cuckooImgWhite}
            alt="logo_cuckoo_black"
            width={30}
            height={30}
          />
        ) : (
          <img
            src={cuckooImgBlack}
            alt="logo_cuckoo_black"
            width={30}
            height={30}
          />
        )}

        <div
          style={{
            color: isDarkTheme
              ? 'white'
              : themeContext.isBlurredBackground
              ? 'white'
              : 'black',
            fontSize: 15,
            marginBottom: 10,
            marginTop: 5,
            textAlign: 'center',
            fontWeight: '500',
          }}>
          <span>© 2020 Cuckoo Corporation</span>
        </div>
      </div>
    </div>
  );
  let cc;
  if (backgroundType === 'image' && cover) {
    // const nonceQs = `?time=${new Date().getTime()}`;
    const nonceQs = '';
    cc = (
      <CImageBackground
        imageStyle={{
          opacity: 0.7,
        }}
        imageClassName="blur-image"
        resizeMode="cover"
        source={`${cover}${nonceQs}`}
        isDarkTheme={isDarkTheme}
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
          minWidth: '100vw',
          backgroundColor: 'black',
        }}>
        {content}
      </CImageBackground>
    );
  } else {
    cc = (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
          minWidth: '100vw',
          backgroundColor: isDarkTheme ? 'black' : 'white',
        }}>
        {content}
      </div>
    );
  }
  return (
    <>
      {activeSections.includes(VIDEO_SECTION_ID) && video !== '' && videoPlay && (
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            zIndex: 2,
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
          onClick={() => setVideoPlay(false)}>
          <YoutubeSection videoId={video} play={videoPlay} />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        {cc}
      </div>
    </>
  );
};

export default ViewLinks;
