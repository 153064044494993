import React from 'react';
import CImg from './cImg';

// ToDo: Unimplemented, Make container to take all the content size
// eslint-disable-next-line max-len
const Component = ({
  imageStyle,
  imageClassName,
  source,
  resizeMode,
  isDarkTheme,
  style,
  children,
  ...props
}) => (
  <div
    style={{
      ...style,
      position: 'relative',
    }}
    {...props}>
    <CImg
      className={imageClassName}
      style={{
        ...imageStyle,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: '100%',
        // zIndex: -1,
      }}
      source={source}
      resizeMode={resizeMode}
      isDarkTheme={isDarkTheme}
    />
    <div
      className="relative-parent"
    >
      {children}
    </div>
  </div>
);

export default Component;
