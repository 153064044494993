import React from 'react';
import IconButton from '../../common/iconButton';

const Component = ({setVideoPlay, src, style, height, videoId, embed}) => {
  // const nonceQs = `?time=${new Date().getTime()}`;
  const nonceQs = '';
  const ncSrc = `${src}${nonceQs}`;
  return (
    <>
      {!embed ? (
        <div
          style={{
            width: '100%',
            ...style,
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: `url(${ncSrc})`,
              height,
              width: '100%',
              // backgroundColor: 'rgb(128)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              marginBottom: 100,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
            {setVideoPlay && (
              <IconButton
                onClick={() => setVideoPlay(true)}
                icon="play"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '25px',
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            ...style,
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: `url(${ncSrc})`,
              height,
              width: '100%',
              // backgroundColor: 'rgb(128)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              marginBottom: 100,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
            {setVideoPlay && (
              <iframe
                title="artistVideo"
                style={{
                  // position: 'absolute',
                  // top: 0,
                  // left: 0,
                  width: '100%',
                  height: '100%',
                }}
                src={`https://www.youtube.com/embed/${videoId}?controls=0`}
                frameBorder="0"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Component;
