import React from 'react';
import CImg from '../../common/cImg';

const Component = ({size, avatar}) => {
  // const nonceQs = `?time=${new Date().getTime()}`;
  const nonceQs = '';
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <CImg
        source={`${avatar}${nonceQs}`}
        resizeMode="cover"
        style={{
          height: size,
          width: size,
          borderRadius: '100%',
          // borderColor: colors.buttonBorder,
          // borderWidth: 2,
          // backgroundColor: 'black',
          // shadowRadius: 20,
          // shadowColor: 'black',
          // shadowOpacity: 0.5,
          // shadowOffset: {
          //   width: 0,
          //   height: 12,
          // },
          
        }}
      />
    </div>
  );
};

export default Component;
