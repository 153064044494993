import React from 'react';
import Icon from './icon';
import CButton from './cButton';
import colors from '../../configs/colors';

const COLOR = colors.buttonText;

const Component = ({icon, iconSize, color, ...props}) => (
  <CButton type="button" {...props}>
    <Icon name={icon} size={iconSize} color={color || COLOR} />
  </CButton>
);

export default Component;
