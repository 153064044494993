import React, {useContext} from 'react';
import classnames from 'classnames';
import Context from '../../components/links/context';

const Component = ({title, children, isDarkTheme, className}) => {
  const themeContext = useContext(Context);

  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        width: '100%',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 30,
          marginBottom: 15,
          marginLeft: 10,
          marginRight: 10,
        }}>
        <span
          style={{
            color: isDarkTheme
              ? 'white'
              : themeContext.isBlurredBackground
                ? 'white'
                : 'black',
            fontSize: 15,
            letterSpacing: 4.2,
            textAlign: 'center',
            // width: '100%',
          }}>
          {title}
        </span>
      </div>
      {children}
    </div>
  );
};

export default Component;
