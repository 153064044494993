import React from 'react';
import CImg from './cImg';

const Component = ({onClick, source, isDarkTheme, style, ...props}) => {
  return (
    <button
      type="button"
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: '#333333',
        backgroundColor: isDarkTheme ? 'black' : 'white',
        height: '100%',
        cursor: 'pointer',
        ...style,
      }}
      onClick={onClick}
      {...props}>
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${source})`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </button>
  );
};

export default Component;
