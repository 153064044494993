import React from 'react';
import CImg from '../cImg';
import colors from '../../../configs/colors';

const Component = ({
  source: asset,
  size,
  style,
  ...props
}) => {
  return (
    <img
      alt="assetImage"
      src={asset}
      {...props}
      style={{
        width: size,
        height: size,
      }}
    />
  );
};

export default Component;
