import React from 'react';
import CImg from '../../common/cImg';

const Component = ({cover}) => {
  // const nonceQs = `?time=${new Date().getTime()}`;
  const nonceQs = '';
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          // marginBottom: 50,
          // marginTop: 50,
        }}>
        <div
          style={{
            display: 'flex',
            height: 260,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
            marginBottom: 10,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            // backgroundColor: 'rgb(128, 128, 128)',
            backgroundImage: `url(${cover}${nonceQs})`,
          }}
        />
      </div>
    </>
  );
};

export default Component;
