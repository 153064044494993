/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext} from 'react';
import ReactGA from 'react-ga';
import {AVAILABLE_SOCIAL_NETWORKS} from '../../../configs/serviceAssets';
import CSection from '../../common/cSection';
import ServiceIcon from '../../common/serviceIcon';
import Context from '../context';

const Component = ({socials = []}) => {
  const themeContext = useContext(Context);
  const {isDarkTheme} = themeContext;
  const countSocialEnable = socials.filter((soc) => soc.valueSwitch === true)
    .length;
  return (
    <CSection isDarkTheme={isDarkTheme} title="FIND ME ON...">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: countSocialEnable === 2 ? 'center' : 'space-around',
          marginTop: 10,
          marginBottom: 40,
          width: '100%',
          flexWrap: 'wrap',
        }}>
        {socials
          .map((social) => {
            const catalog = AVAILABLE_SOCIAL_NETWORKS.find(
              (item) => social.id === item.id,
            );
            return {
              catalog,
              ...social,
            };
          })
          .filter(({catalog}) => catalog)
          .map(({catalog, ...rest}) => ({...catalog, ...rest}))
          .filter(({valueSwitch}) => valueSwitch)
          .map(({icon, value, id: socialId}) => {
            // const url = handlerToUrl(value);
            const url = value;
            return (
              <div
                key={JSON.stringify(icon)}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: countSocialEnable === 2 ? '5%' : 0,
                  marginRight: countSocialEnable === 2 ? '5%' : 0,
                }}>
                <a
                  style={{
                    // display: 'flex',
                    textDecoration: 'none',
                  }}
                  href={url}>
                  <a
                    type="button"
                    style={{
                      backgroundColor: 'transparent',
                      borderRadius: 999,
                      borderColor: isDarkTheme ? 'black' : 'white',
                      borderWidth: 0,
                      width: 40,
                      height: 40,
                      // display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      ReactGA.event({
                        action: 'linkVisited',
                        category: socialId,
                        label: socialId,
                      });
                      window.fbq('track', 'ViewContent', {
                        content_name: socialId,
                        content_category: 'social_link_click',
                      });
                    }}>
                    <ServiceIcon icon={icon} size={40} />
                  </a>
                </a>
              </div>
            );
          })}
      </div>
    </CSection>
  );
};

export default Component;
