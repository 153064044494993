import {unAuthAxiosCall} from './axiosCall';
import {
  SOCIALS_SECTION_ID,
  VISIT_SECTION_ID,
  STREAMING_SECTION_ID,
  HIDEABLE_SECTION_IDS,
} from '../../configs/serviceSettingOptions';
import {AVAILABLE_SOCIAL_NETWORKS} from '../../configs/serviceAssets';

function filterLinkType(ll, typeIn) {
  return ll
    .filter(({type}) => type === typeIn)
    .sort(
      ({position: position1}, {position: position2}) => position1 - position2,
    );
}

function filterLinkTypeSocials(ll, typeIn) {
  const socials = ll.filter(({type}) => type === typeIn);
  const result = AVAILABLE_SOCIAL_NETWORKS.map((catalog) => {
    return socials.find((sc) => sc.subtype === catalog.id);
  })
    .sort(
      ({position: position1}, {position: position2}) => position1 - position2,
    )
    .filter((social) => social && social.linkId);
  // console.log('result1', result);

  return result;
}

function api2Native(response_data) {
  return {
    // ...response_data.data,
    pageId: response_data.pageId,
    artistName: response_data.artistName || '',
    description: response_data.description || '',
    coverTitle: response_data.title || '',
    cover: response_data.backgroundImage || null,
    customUrl: response_data.url || '',
    avatar: response_data.logo || null,
    video: response_data.backgroundVideo || '',
    theme: response_data.backgroundColor || '',
    backgroundType: response_data.backgroundEnabled ? 'image' : 'color',
    fbPixelId: response_data.fbPixelId,
    visits: filterLinkType(
      response_data.links,
      'Website',
    ).map(({url, label}, ii) => ({link: url, value: label})),
    socials: filterLinkTypeSocials(response_data.links, 'Social').map(
      ({url, subtype, enabled}) => {
        // ToDo: url might be just the handler?
        const social = {
          id: subtype,
          value: url,
          valueSwitch: enabled,
        };
        return social;
      },
    ),
    streamings: filterLinkType(response_data.links, 'Music').map(
      ({url, subtype, enabled, position}) => ({
        id: subtype,
        value: url,
        valueSwitch: enabled,
        position,
      }),
    ),
    sectionsOrder: response_data.sectionsOrder || [
      STREAMING_SECTION_ID,
      VISIT_SECTION_ID,
      SOCIALS_SECTION_ID,
    ],
    sectionsActivation: HIDEABLE_SECTION_IDS.map((idd) => {
      const rr = response_data.sectionsActivation
        ? response_data.sectionsActivation.find(({id}) => id === idd)
        : null;
      return {
        id: idd,
        active: rr ? rr.active !== false : true,
      };
    }),
  };
}

export const getWebPage = (url, pageId) => {
  const qq = {};
  if (url) {
    qq.url = url;
  }
  if (pageId) {
    qq.pageId = pageId;
  }
  return unAuthAxiosCall('/api/web/pages', {
    method: 'GET',
    query: qq,
  }).then((response) => api2Native(response.data));
};

export const getVideoThumbnail = async (videoId) => {
  // ToDo: Make a public service to query thumbnail
  const response = await unAuthAxiosCall('/api/resources/videos/thumbnail', {
    method: 'GET',
    query: {
      id: videoId,
    },
  });
  return Object.values(response.data).sort(
    ({height: height1, width: width1}, {height: height2, width: width2}) =>
      height2 * width2 - height1 * width1,
  )[0].url;
};
