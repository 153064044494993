import React, {useState, useEffect} from 'react';
import YouTube from 'react-youtube';
// import Spinner from '../../../../lib/components/components/spinner';

const YoutubeSection = ({videoId}) => {
  const opts = {
    // height: 400,
    // width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  const onReady = (event) => event.target.playVideo();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
      }}>
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 3,
          flex: 1,
        }}>
        <YouTube
          containerClassName="container-video"
          videoId={videoId}
          opts={opts}
          onReady={onReady}
        />
      </div>
    </div>
  );
};

export default YoutubeSection;
