import React from 'react';

const CErrorMessage = ({error, code = 404}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: 'radial-gradient(grey, transparent)',
      }}>
      {code && (
        <div>
          <h1
            style={{
              color: 'rgb(242,26,71)',
              fontSize: 40,
              letterSpacing: 4.2,
            }}>
            Code: {code}
          </h1>
        </div>
      )}
      <div>
        <h1
          style={{
            fontSize: 40,
            letterSpacing: 4.2,
          }}>
          {error}
        </h1>
      </div>
    </div>
  );
};
export default CErrorMessage;
