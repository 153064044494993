import React from 'react';
import classnames from 'classnames';
import CImg from '../cImg';

import camera from '../../../res/images/c_icons/camera.png';
import link from '../../../res/images/c_icons/link.png';
import pencil_alt from '../../../res/images/c_icons/pencil-alt.png';
import plus from '../../../res/images/c_icons/plus.png';
import search from '../../../res/images/c_icons/search-alt.png';
import times from '../../../res/images/c_icons/times.png';
import times_alt from '../../../res/images/c_icons/times-alt.png';
import video from '../../../res/images/c_icons/video.png';
import play from '../../../res/images/c_icons/play-circle.png';
import music from '../../../res/images/c_icons/music-solid.png';

const CUSTOM_ICONS = new Map([
  ['camera', camera],
  ['link', link],
  ['pencil', pencil_alt],
  ['pencil-alt', pencil_alt],
  ['plus', plus],
  ['search', search],
  ['times', times_alt],
  ['video', video],
  ['play', play],
  ['music-solid', music],
]);

const NAME_2_TYPE = new Map([
  ['facebook-f', 'fab'],
]);

const Component = ({name, color, size, style, ...props}) => {
  let type = 'fa';
  if (NAME_2_TYPE.has(name)) {
    type = NAME_2_TYPE.get(name);
    return (
      <i
        style={{color, ...style}}
        className={classnames(type, `fa-${name}`)}
        {...props}
      />
    );
  }
  if (CUSTOM_ICONS.has(name)) {
    const asset = CUSTOM_ICONS.get(name);
    return (
      <img
        src={asset}
        color={color}
        width={size}
        height={size}
        alt="buttonImage"
        {...props}
      />
    );
  }
  return <input {...props} value={name} />;
};

export default Component;
