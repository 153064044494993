import React, {useContext} from 'react';
import StreamingSection from './streamingSection';
import CSection from '../../common/cSection';
import CoverSection from './coverSection';
import Context from '../context';

const Component = ({
  cover,
  coverTitle,
  streamings,
  disabledCover,
  disabledStreamings,
  disableVideo,
}) => {
  const themeContext = useContext(Context);
  const {isDarkTheme} = themeContext;
  return (
    <CSection isDarkTheme={isDarkTheme} title={coverTitle}>
      <>
        {!disabledCover && !disableVideo && <CoverSection cover={cover} />}
        {!disabledStreamings &&
          streamings.filter(({valueSwitch}) => valueSwitch).length > 0 && (
          <StreamingSection streamings={streamings} />
        )}
      </>
    </CSection>
  );
};

export default Component;
