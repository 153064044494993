import React from 'react';
import './index.scss';

const Loader = () => (
  <div className="loader">
    <div className="loader-indicator" />
  </div>
);

export default Loader;
