import React, {useContext} from 'react';
import ReactGA from "react-ga";
import MD5 from "crypto-js/md5";
import CSection from '../../common/cSection';
import Context from '../context';
import validatejs from 'validate.js';

function getLinkHash(pageId, value) {
  return `Cu#${pageId}#${MD5(value.link.toLowerCase()).toString().toLowerCase()}`;
}

const Component = ({values = [], style, pageId}) => {
  const themeContext = useContext(Context);
  const {isDarkTheme} = themeContext;

  const isEmail = ({link, value}) => {
    return !validatejs({value: link}, {value: {email: true}});
  };

  return (
    <CSection isDarkTheme={isDarkTheme} title="VISIT" style={style}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}>
        {values.map((value, ii) => (
          <a
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              width: '80%',
              textDecoration: 'none',
            }}
            href={isEmail(value) ? `mailto:${value.link}` : value.link}
            key={ii}>
            <button
              type="button"
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                marginTop: 10,
                marginBottom: 10,
                borderColor: 'rgb(127, 127, 127)',
                backgroundColor: 'rgb(51, 51, 51)',
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                flex: 1,
                cursor: 'pointer',
              }}
              onClick={() => {
                const linkId = getLinkHash(pageId, value);
                ReactGA.event({
                  action: 'linkVisited',
                  category: linkId,
                  label: linkId,
                });
                window.fbq(
                  'track',
                  'ViewContent',
                  {
                    content_name: linkId,
                    content_category: 'custom_link_click',
                  }
                );
              }}
            >
              <span
                style={{
                  fontSize: 12,
                  letterSpacing: 4.2,
                  fontWeight: 'bold',
                  color: 'white',
                }}>
                {value.value}
              </span>
            </button>
          </a>
        ))}
      </div>
    </CSection>
  );
};

export default Component;
