import React from 'react';
import classnames from 'classnames';
import colors from '../../configs/colors';

const COLOR = colors.buttonText;
const BACKGROUND_COLOR = colors.buttonBackground;

const Component = ({style, className, children, ...props}) => (
  <button
    type="button"
    className={className}
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      borderWidth: 1,
      borderColor: COLOR,
      backgroundColor: BACKGROUND_COLOR,
      ...style,
    }}
    {...props}>
    {children}
  </button>
);

export default Component;
