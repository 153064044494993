// banners
import iheartradio_light from '../res/images/banners/iheartradio/light.png';
import iheartradio_dark from '../res/images/banners/iheartradio/dark.png';
import amazonmusic_light from '../res/images/banners/amazonmusic/light.png';
import amazonmusic_dark from '../res/images/banners/amazonmusic/dark.png';
import applemusic_light from '../res/images/banners/appleMusic/light.png';
import applemusic_dark from '../res/images/banners/appleMusic/dark.png';
import bandcamp_light from '../res/images/banners/bandcamp/light.png';
import bandcamp_dark from '../res/images/banners/bandcamp/dark.png';
import beatstars_light from '../res/images/banners/beatstars/light.png';
import beatstars_dark from '../res/images/banners/beatstars/dark.png';
import deezer_light from '../res/images/banners/deezer/light.png';
import deezer_dark from '../res/images/banners/deezer/dark.png';
import audiomack_light from '../res/images/banners/audiomack/light.png';
import audiomack_dark from '../res/images/banners/audiomack/dark.png';
import soundcloud_light from '../res/images/banners/soundcloud/light.png';
import soundcloud_dark from '../res/images/banners/soundcloud/dark.png';
import spotify_light from '../res/images/banners/spotify/light.png';
import spotify_dark from '../res/images/banners/spotify/dark.png';
import tidal_light from '../res/images/banners/tidal/light.png';
import tidal_dark from '../res/images/banners/tidal/dark.png';
import youtubeMusic_light from '../res/images/banners/youtubeMusic/light.png';
import youtubeMusic_dark from '../res/images/banners/youtubeMusic/dark.png';
import tiktokMusic_light from '../res/images/banners/tiktok/light.png';
import tiktokMusic_dark from '../res/images/banners/tiktok/dark.png';
// icons
import icon_iheartradio from '../res/images/brandIcons/icon_iheartradio/1x.png';
import icon_tiktok from '../res/images/brandIcons/icon_tiktok/1x.png';
import icon_twitter from '../res/images/brandIcons/icon_twitter/1x.png';
import icon_tiktokmusic from '../res/images/brandIcons/icon_tiktokmusic/1x.png';
import icon_instagram from '../res/images/brandIcons/icon_instagram/1x.png';
import icon_beatstars from '../res/images/brandIcons/icon_beatstars/1x.png';
import icon_deezer from '../res/images/brandIcons/icon_deezer/1x.png';
import icon_tidal from '../res/images/brandIcons/icon_tidal/1x.png';
import icon_audiomack from '../res/images/brandIcons/icon_audiomack/1x.png';
import icon_youtube_music from '../res/images/brandIcons/icon_youtube_music/1x.png';
import icon_facebook from '../res/images/brandIcons/icon_facebook/1x.png';
import icon_youtube from '../res/images/brandIcons/icon_youtube/1x.png';
import icon_amazon from '../res/images/brandIcons/icon_amazon/1x.png';
import icon_apple from '../res/images/brandIcons/icon_apple/1x.png';
import icon_spotify from '../res/images/brandIcons/icon_spotify/1x.png';
import icon_snapchat from '../res/images/brandIcons/icon_snapchat/1x.png';
import icon_linkedin from '../res/images/brandIcons/icon_linkedin/1x.png';
import icon_bandcamp from '../res/images/brandIcons/icon_bandcamp/1x.png';
import icon_soundcloud from '../res/images/brandIcons/icon_soundcloud/1x.png';

export const AVAILABLE_SOCIAL_NETWORKS = [
  {
    id: 'fb',
    icon: {
      path: icon_facebook,
    },
  },
  {
    id: 'ig',
    icon: {
      path: icon_instagram,
    },
  },
  {
    id: 'tw',
    icon: {
      path: icon_twitter,
    },
  },
  {
    id: 'yt',
    icon: {
      path: icon_youtube,
    },
  },
  {
    id: 'tt',
    icon: {
      path: icon_tiktok,
    },
  },
  {
    id: 'sn',
    icon: {
      path: icon_snapchat,
    },
  },
  {
    id: 'in',
    icon: {
      path: icon_linkedin,
    },
  },
].map((ss) => {
  return {
    ...ss,
  };
});

export const AMAZON = 'am';
export const APPLE = 'ap';
export const BANDCAMP = 'bc';
export const BEATSTARTS = 'bs';
export const DEEZER = 'dz';
export const SOUNDCLOUD = 'sc';
export const SPOTIFY = 'sp';
export const TIDAL = 'td';
export const AUDIOMACK = 'aumk';
export const YOUTUBE_MUSIC = 'ytm';
export const TIKTOK_MUSIC = 'ttm';
export const I_HEART_RADIO = 'ihr';

export const AVAILABLE_SOCIAL_STREAMINGS = [
  {
    id: I_HEART_RADIO,
    name: 'I Heart Radio',
    icon: {
      path: icon_iheartradio,
      priority: 6,
    },
    banner: {
      src: {
        dark: iheartradio_dark,
        light: iheartradio_light,
      },
      order: 13,
    },
  },
  {
    id: AMAZON,
    icon: {
      path: icon_amazon,
    },
    banner: {
      src: {
        dark: amazonmusic_dark,
        light: amazonmusic_light,
      },
      order: 7,
    },
  },
  {
    id: APPLE,
    icon: {
      path: icon_apple,
    },
    banner: {
      src: {
        dark: applemusic_dark,
        light: applemusic_light,
      },
      order: 2,
    },
  },
  {
    id: BANDCAMP,
    icon: {
      path: icon_bandcamp,
    },
    banner: {
      src: {
        dark: bandcamp_dark,
        light: bandcamp_light,
      },
      order: 11,
    },
  },
  {
    id: BEATSTARTS,
    icon: {
      path: icon_beatstars,
    },
    banner: {
      src: {
        dark: beatstars_dark,
        light: beatstars_light,
      },
      order: 9,
    },
  },
  {
    id: DEEZER,
    icon: {
      path: icon_deezer,
    },
    banner: {
      src: {
        dark: deezer_dark,
        light: deezer_light,
      },
      order: 4,
    },
  },
  {
    id: TIKTOK_MUSIC,
    icon: {
      path: icon_tiktokmusic,
    },
    banner: {
      src: {
        dark: tiktokMusic_dark,
        light: tiktokMusic_light,
      },
      order: 6,
    },
  },
  {
    id: SOUNDCLOUD,
    icon: {
      path: icon_soundcloud,
    },
    banner: {
      src: {
        dark: soundcloud_dark,
        light: soundcloud_light,
      },
      order: 12,
    },
  },
  {
    id: SPOTIFY,
    icon: {
      path: icon_spotify,
    },
    banner: {
      src: {
        dark: spotify_dark,
        light: spotify_light,
      },
      order: 1,
    },
  },
  {
    id: TIDAL,
    icon: {
      path: icon_tidal,
    },
    banner: {
      src: {
        dark: tidal_dark,
        light: tidal_light,
      },
      order: 5,
    },
  },
  {
    id: AUDIOMACK,
    icon: {
      path: icon_audiomack,
    },
    banner: {
      src: {
        dark: audiomack_dark,
        light: audiomack_light,
      },
      order: 10,
    },
  },
  {
    id: YOUTUBE_MUSIC,
    icon: {
      path: icon_youtube_music,
    },
    banner: {
      src: {
        dark: youtubeMusic_dark,
        light: youtubeMusic_light,
      },
      order: 3,
    },
  },
];


const AVAILABLE_SOCIAL_STREAMINGS_MAX = Math.max(...AVAILABLE_SOCIAL_STREAMINGS.map(
  ({banner: {order}}) => order
));

export function socialStreamingComparator({catalog: catalog1, item: item1}, {catalog: catalog2, item: item2}){
  if ((!item1 || item1.position === undefined) && (!item2 || item2.position === undefined)) {
    return (catalog1.banner.order - AVAILABLE_SOCIAL_STREAMINGS_MAX) - (catalog2.banner.order - AVAILABLE_SOCIAL_STREAMINGS_MAX);
  }
  if (!item1 || item1.position === undefined) {
    return 1;
  }
  if (!item2 || item2.position === undefined) {
    return -1;
  }
  return item1.position - item2.position;
}
