import React from 'react';

// // ToDo: Unimplemented
const Component = ({resizeMode, style, source, isDarkTheme, ...props}) =>
  isDarkTheme ? (
    <div
      style={{
        backgroundImage: `url(${source})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: resizeMode,
        ...style,
      }}
      {...props}
    />
  ) : (
    <div
      className="custom-shadow"
      style={{
        backgroundImage: `url(${source})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: resizeMode,
        ...style,
      }}
      {...props}
    />
  );

export default Component;
