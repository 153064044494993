export const VIDEO_SECTION_ID = 'video';
export const VISIT_SECTION_ID = 'visits';
export const STREAMING_SECTION_ID = 'streaming';
export const SOCIALS_SECTION_ID = 'socials';
export const AVATAR_SECTION_ID = 'avatar';
export const COVER_SECTION_ID = 'cover';
export const HIDEABLE_SECTION_IDS = [
  VIDEO_SECTION_ID,
  VISIT_SECTION_ID,
  STREAMING_SECTION_ID,
  SOCIALS_SECTION_ID,
  AVATAR_SECTION_ID,
  COVER_SECTION_ID,
];
