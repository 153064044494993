import React, {useEffect, useState} from 'react';
import {pathToRegexp} from 'path-to-regexp';
import ReactGA from 'react-ga';
import {GA_TRACKING_ID} from '../../config';
import Links from '../links';
import {getWebPage} from '../../services/api/linksService';
import ClientError from '../../lib/utils/exceptions';
import Loader from '../common/loader';
import CErrorMessage from '../common/cErrorMessage';
import './index.scss';

ReactGA.initialize(GA_TRACKING_ID);

const PAGE_ID_RE = pathToRegexp('/n/:pageId');
const PAGE_URL_RE = pathToRegexp('/:pageUrl');

const facebookCode = () => {
  if (window.fbq) return;
  window.fbq = (...args) => {
    window.fbq.callMethod
      ? window.fbq.callMethod.apply(window.fbq, args)
      : window.fbq.queue.push(args);
  };
  if (!window._fbq) window._fbq = window.fbq;
  window.fbq.push = window.fbq;
  window.fbq.loaded = !0;
  window.fbq.version = '2.0';
  window.fbq.queue = [];
  const tagScript = window.document.createElement('script');
  tagScript.async = !0;
  tagScript.src = 'https://connect.facebook.net/en_US/fbevents.js';
  const firstScript = window.document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(tagScript, firstScript);
};

function getCurrentPageId() {
  // const pageId = getQueryParams(window.location, pageId);
  const res = PAGE_ID_RE.exec(window.location.pathname);
  return res ? res[1] : null;
}

function getCurrentPageUrl() {
  // const pageId = getQueryParams(window.location, pageId);
  const res = PAGE_URL_RE.exec(window.location.pathname);
  return res ? res[1] : null;
}

const Component = () => {
  const [webPage, setWebPage] = useState(null);
  const [error, setError] = useState(null);
  const [codeError, setCodeError] = useState(null);

  useEffect(() => {
    const pageId = getCurrentPageId();
    let pageUrl;
    if (!pageId) {
      pageUrl = getCurrentPageUrl();
      if (!pageUrl) {
        setError('Page Not Found');
        setCodeError('404');
        return;
      }
    }
    getWebPage(pageUrl, pageId).then(
      (data) => {
        setWebPage(data);
        const pagepathname = `/n/${data.pageId}`;
        ReactGA.set({page: pagepathname});
        ReactGA.pageview(pagepathname);
        if (data.fbPixelId) {
          facebookCode();
          window.fbq('init', data.fbPixelId);
          window.fbq('track', 'PageView');
        }
      },
      (err) => {
        setError(
          err instanceof ClientError ? err.message : 'Something went wrong.',
        );
      },
    );
  }, []);

  if (!webPage && !error) {
    return <Loader />;
  }
  if (error) {
    return <CErrorMessage error={error} code={codeError} />;
  }
  return <Links webPage={webPage} />;
};

export default Component;
