import React, {useState, useEffect} from 'react';
import VideoSection from './videoSection';
import AvatarSection from './avatarSection';

function getMeta(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve([this.width, this.height]);
    };
    img.onerror = function (err) {
      reject(err);
    };
    img.src = url;
  });
}

const Component = ({
  setVideoPlay,
  thumbnailVideo,
  cover,
  avatar,
  style,
  disabledVideo,
  disabledAvatar,
  disableVideo,
  video,
  embed,
}) => {
  const coverSrc = !disableVideo ? thumbnailVideo : cover;
  const [valueAspectRatio, setAspectRatio] = useState(null);
  useEffect(() => {
    if (coverSrc) {
      getMeta(coverSrc).then(([width, height]) =>
        setAspectRatio(width / height),
      );
    }
  }, [coverSrc]);
  const div = embed && window && window.innerWidth ? window.innerWidth : 400;
  const coverHeight = valueAspectRatio && div / valueAspectRatio;
  return (
    <>
      {!disabledVideo && (
        <VideoSection
          setVideoPlay={!disableVideo && thumbnailVideo ? setVideoPlay : null}
          src={coverSrc}
          style={style}
          height={coverHeight}
          videoId={video}
          embed={embed}
        />
      )}
      {!disabledAvatar  && (
        <div
          style={
            !disabledVideo && (thumbnailVideo || cover) && coverHeight
              ? {
                  position: 'absolute',
                  top: coverHeight && coverHeight - 150 / 2,
                  left: 0,
                  right: 0,
                  // height: '100%',
                }
              : {
                  height: 210,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 20,
                }
          }>
          <AvatarSection avatar={avatar} size={150} />
        </div>
      )}
    </>
  );
};

export default Component;
